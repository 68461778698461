/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { GetUserById, GetCourseDataByUserId } from "../../services/Api/Api";
import { Card } from "primereact/card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Table, Modal } from "antd";
import moment from "moment";

const ViewCustomer = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [userData, setUserData] = useState({});
	const [courseData, setCourseData] = useState([]);
	const [testMarksData, setTestMarksData] = useState([]);
	const [activeTab, setActiveTab] = useState("course");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [totalMarks, setTotalMarks] = useState("");

	useLayoutEffect(() => {
		const fetchUserData = async () => {
			try {
				const res = await GetUserById(id);
				setUserData(res.data.data);
			} catch (err) {
				console.error("Error fetching user data:", err);
			}
		};
		fetchUserData();
	}, [id]);

	const getData = async (type) => {
		try {
			const formData = { type };
			const result = await GetCourseDataByUserId(formData, id);
			const dataWithIndex = result.data.data.data.map((item, index) => ({
				...item,
				autoIncrementId: index + 1,
			}));
			setCourseData(dataWithIndex);
		} catch (e) {
			console.error("Error fetching course data:", e);
		}
	};

	useEffect(() => {
		getData("course");
	}, [id]);

	const navigateToUser = () => {
		navigate("/users");
	};

	const handleTabSelect = (key) => {
		setActiveTab(key);
		getData(key);
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
		setTestMarksData([]);
	};

	const columnsCourse = [
		{
			title: "ID",
			dataIndex: "autoIncrementId",
			key: "id",
		},
		{
			title: "Course Name",
			dataIndex: ["dashboard_course", "name"],
			key: "name",
		},
		{
			title: "Course Price",
			dataIndex: ["dashboard_course", "price"],
			key: "price",
		},
		{
			title: "Progress",
			dataIndex: "progress",
			key: "progress",
		},
		{
			title: "Certificate",
			dataIndex: "certificate_url",
			key: "certificate_url",
			render: (certificate_url) =>
				certificate_url ? (
					<a
						href={certificate_url}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: "blue", textDecoration: "underline" }}
					>
						View Certificate
					</a>
				) : (
					"--"
				),
		},
		{
			title: "Purchase Date",
			dataIndex: "created_at",
			key: "created_at",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
	];

	const columnsTest = [
		{
			title: "ID",
			dataIndex: "autoIncrementId",
			key: "id",
		},
		{
			title: "Test Name",
			dataIndex: ["dashboard_test", "name"],
			key: "name",
		},
		{
			title: "Test Duration",
			dataIndex: ["dashboard_test", "duration"],
			key: "duration",
		},
		{
			title: "Number of Questions",
			dataIndex: ["dashboard_test", "question_count"],
			key: "question_count",
		},
		{
			title: "Purchase Date",
			dataIndex: "created_at",
			key: "created_at",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "Action",
			key: "action",
			render: (record) =>
				record.dashboard_test?.test_marks?.length > 0 ? (
					<p
						onClick={() => {
							setTestMarksData(record.dashboard_test.test_marks);
							setTotalMarks(record.dashboard_test.question_count);
							setIsModalVisible(true);
						}}
						style={{
							color: "blue",
							cursor: "pointer",
							fontSize: "large",
						}}
					>
						View Report
					</p>
				) : (
					"Not Attempted Yet"
				),
		},
	];

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="30px"
			>
				<div>
					<h3 className="page-title">USER MANAGEMENT</h3>
					<p className="page-sub-title">View Information related to User</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToUser}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Users</span>
					</Button>
				</div>
			</Box>
			<Form className="admin_details_form">
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Card
						style={{ width: "100%", marginRight: "20px", marginBottom: "40px" }}
					>
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "repeat(3, 1fr)",
								gap: "20px",
								marginBottom: "40px",
							}}
						>
							<div>
								<h5
									style={{
										fontSize: "0.9rem",
										fontFamily: "Cerebri Sans,sans-serif",
										fontWeight: "700",
										marginTop: "14px",
										color: "black",
									}}
								>
									User's Name:
								</h5>
								<p>{userData?.user_profile?.name || "---"}</p>
							</div>
							<div>
								<h5
									style={{
										fontSize: "0.9rem",
										fontFamily: "Cerebri Sans,sans-serif",
										fontWeight: "700",
										marginTop: "14px",
										color: "black",
									}}
								>
									Email
								</h5>
								<p>{userData?.email || "---"}</p>
							</div>
							<div>
								<h5
									style={{
										fontSize: "0.9rem",
										fontFamily: "Cerebri Sans,sans-serif",
										fontWeight: "700",
										marginTop: "14px",
										color: "black",
									}}
								>
									Mobile:
								</h5>
								<p>{userData?.user_profile?.mobile || "---"}</p>
							</div>
						</div>
					</Card>
					<Card>
						<Form className="admin_details_form">
							<div>
								<h5 style={{ marginBottom: "20px" }}>
									View all the data associated with{" "}
									{userData?.user_profile?.name}
								</h5>
								<Tabs activeKey={activeTab} onSelect={handleTabSelect}>
									<Tab eventKey="course" title="Course">
										<div style={{ marginTop: "20px" }}>
											<Table
												columns={columnsCourse}
												dataSource={courseData}
												rowKey="autoIncrementId"
											/>
										</div>
									</Tab>
									<Tab eventKey="test" title="Test">
										<div style={{ marginTop: "20px" }}>
											<Table
												columns={columnsTest}
												dataSource={courseData}
												rowKey="autoIncrementId"
											/>
										</div>
									</Tab>
								</Tabs>
							</div>
						</Form>
					</Card>
				</div>
			</Form>
			<Modal
				centered
				width={800}
				title="Test Marks Details"
				visible={isModalVisible}
				onCancel={handleModalClose}
				footer={null}
			>
				<Table
					columns={[
						{
							title: "Attempt Number",
							dataIndex: "attempt",
							key: "attempt",
						},
						{
							title: "Marks",
							dataIndex: "test_marks",
							key: "test_marks",
							render: (test_marks) => `${test_marks}/${totalMarks || "--"}`,
						},
						{
							title: "Test Attempt Date",
							dataIndex: "created_at",
							key: "created_at",
							render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
						},
					]}
					dataSource={testMarksData}
					rowKey="autoIncrementId"
					pagination={false}
				/>
			</Modal>
		</Box>
	);
};

export default ViewCustomer;
