/** @format */

import React, { useEffect, useState } from "react";
import { Space, Table, message } from "antd";
import { GetForum, DeleteForum } from "../../../services/Api/ContentApi";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Box } from "@material-ui/core";
import moment from "moment/moment";
import { Button } from "primereact/button";
import Alert from "../../Customer/Alert";

const Forum = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 50,
		},
		sortField: null,
		sortOrder: null,
	});

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
			width: "5%",
		},
		{
			title: "Name",
			dataIndex: ["forum_user", "user_profile", "name"],
			width: "10%",
			render: (name, record) => name || record.forum_user?.user_profile?.name || "Name not provided",
		},
		{
			title: "Question",
			dataIndex: "question",
			width: "30%",
		},
        {
			title: "Answer",
			dataIndex: "answer",
			width: "30%",
			render: (answer) => (
				<span style={{ color: answer ? "inherit" : "red" }}>
					{answer ? truncateText(answer, 10) : "Answer is not provided by admin yet"}
				</span>
			),
		},
		{
			title: "Date",
			dataIndex: "created_at",
			width: "30%",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						style={{ borderRadius: "25px" }}
						onClick={(event) => navigateToEditForum(event, record.id)}
					/>
					<Alert title="Course" handleDelete={() => removeUser(record.id)} />
				</Space>
			),
		},
	];

    const truncateText = (text, maxWords) => {
		const words = text.split(' ');
		return words.length > maxWords ? words.slice(0, maxWords).join(' ') + '...' : text;
	};


	const navigateToEditForum = (event, id) => {
		navigate(`/editForum/${id}`);
	};

	// Get all support queries
	const getData = async (params = {}) => {
		try {
			setLoading(true);
			let result = await GetForum(localStorage.getItem("adminToken"), params);
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
			}));
			setData(newData);
			setUserBackupData(newData);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = LIST.filter(
			(item) =>
				item.forum_user?.user_profile?.name.toLowerCase().includes(searchField.toLowerCase())
		);
		setData(searchList);
	};

	const removeUser = (user_id) => {
		DeleteForum(user_id, localStorage.getItem("adminToken"))
			.then((res) => {
				message.success(res?.data?.message);
				getData();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">FORUM MANAGEMENT</h3>
					<p className="page-sub-title">Manage User Queries By Answering Them</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => {
									onSearch(e.target.value);
								}}
								placeholder="Search..."
							/>
						</span>
					</Box>
				</Box>
			</Box>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
		</Box>
	);
};

export default Forum;
